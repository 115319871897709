// import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
// import useGetMarketplaceManagers from "../libs/marketplaceManagers";
// import { Fragment, useState, useRef, useCallback } from "react";
// import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
// import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
// import { GoPlusCircle } from "react-icons/go";
// import useDeactivateAccount from "../libs/deactivateAccount";
// import useBlockUserById from "../libs/blockUserById";
// import useUnblockUserById from "../libs/unblockUserById";
// import Spinner from "../../utils/spinner";
// import { useNavigate } from "react-router-dom";
// import "./Superadminm-manager.css";

// function Superadminmrarketmanager() {
//   const {
//     data,
//     isLoading,
//     isError,
//     fetchNextPage,
//     hasNextPage,
//     isFetchingNextPage,
//   } = useGetMarketplaceManagers();

//   const marketplaceManagerArray = data?.pages.flatMap(page => page.marketplaceManagers) || [];
  
//   const navigate = useNavigate();
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectedMarketplaceManagerId, setSelectedMarketplaceManagerId] = useState(null);
//   const [blockOverlay, setBlockOverlay] = useState(false);
//   const [unblockOverlay, setUnblockOverlay] = useState(false);
//   const [deactivateOverlay, setDeactivateOverlay] = useState(false);
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");

//   const blockMarketplaceManager = useBlockUserById();
//   const unblockMarketplaceManager = useUnblockUserById();
//   const deactivateMarketplaceManager = useDeactivateAccount();

//   const filteredMarketplaceManagers = marketplaceManagerArray.filter(
//     (manager) =>
//       manager.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       manager.last_name.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const observerRef = useRef();
//   const lastProductElementRef = useCallback(
//     (node) => {
//       if (isFetchingNextPage) return;

//       if (observerRef.current) observerRef.current.disconnect();

//       observerRef.current = new IntersectionObserver((entries) => {
//         if (entries[0].isIntersecting && hasNextPage) {
//           fetchNextPage();
//         }
//       }, { threshold: 1.0 });

//       if (node) observerRef.current.observe(node);
//     },
//     [isFetchingNextPage, fetchNextPage, hasNextPage]
//   );

//   const handleUnblockUser = async () => {
//     try {
//       if (!selectedMarketplaceManagerId) {
//         console.error("User ID not available.");
//         return;
//       }

//       await unblockMarketplaceManager.mutateAsync({
//         userId: selectedMarketplaceManagerId,
//       });
//       setUnblockOverlay(false);
//       setSelectedMarketplaceManagerId(null);
//     } catch (error) {
//       console.error("Failed to unblock user:", error.message);
//     }
//   };

//   const handleBlockUser = async () => {
//     try {
//       if (!selectedMarketplaceManagerId) {
//         console.error("User ID not available.");
//         return;
//       }

//       await blockMarketplaceManager.mutateAsync({
//         userId: selectedMarketplaceManagerId,
//         blockData: { startDate, endDate },
//       });
//       setBlockOverlay(false);
//       setSelectedMarketplaceManagerId(null);
//     } catch (error) {
//       console.error("Failed to block user:", error.message);
//     }
//   };

//   const handleDeactivateAccount = async () => {
//     try {
//       if (!selectedMarketplaceManagerId) {
//         console.error("User ID not available.");
//         return;
//       }

//       await deactivateMarketplaceManager.mutateAsync(
//         selectedMarketplaceManagerId
//       );
//       setDeactivateOverlay(false);
//       setSelectedMarketplaceManagerId(null);
//     } catch (error) {
//       console.error("Failed to deactivate account:", error.message);
//     }
//   };

//   return (
//     <Fragment>
//       {blockOverlay && (
//         <Fragment>
//           <div className="block-over-container">
//             <form>
//               <p>Are you sure you want to suspend this Marketplace Admin?</p>
//               <label>
//                 Start Date
//                 <input
//                   type="date"
//                   value={startDate}
//                   onChange={(e) => setStartDate(e.target.value)}
//                 />
//               </label>
//               <label> 
//                 End Date
//                 <input
//                   type="date"
//                   value={endDate}
//                   onChange={(e) => setEndDate(e.target.value)}
//                 />
//               </label>
//               <div className="btn">
//                 <button onClick={() => setBlockOverlay(false)}>Cancel</button>
//                 <button
//                   type="button"
//                   onClick={handleBlockUser}
//                   disabled={blockMarketplaceManager.isLoading}
//                 >
//                   {blockMarketplaceManager.isLoading ? "Blocking..." : "Confirm"}
//                 </button>
//               </div>
//               {blockMarketplaceManager.isError && (
//                 <p className="error-message">
//                   Error: {blockMarketplaceManager.error.message}
//                 </p>
//               )}
//             </form>
//           </div>
//         </Fragment>
//       )}

//       {unblockOverlay && (
//         <Fragment>
//           <div className="unblock-over-container">
//             <div className="inner">
//               <p>
//                 Are you sure you want to lift the suspension on this Marketplace Admin?
//               </p>
//               <div className="btn">
//                 <button onClick={() => setUnblockOverlay(false)}>Cancel</button>
//                 <button
//                   type="button"
//                   onClick={handleUnblockUser}
//                   disabled={unblockMarketplaceManager.isLoading}
//                 >
//                   {unblockMarketplaceManager.isLoading
//                     ? "Unblocking..."
//                     : "Confirm"}
//                 </button>
//               </div>
//               {unblockMarketplaceManager.isError && (
//                 <p className="error-message">
//                   Error: {unblockMarketplaceManager.error.message}
//                 </p>
//               )}
//             </div>
//           </div>
//         </Fragment>
//       )}

//       {deactivateOverlay && (
//         <Fragment>
//           <div className="delete-over-container">
//             <div className="delete-card">
//               <TrashIcon />
//               <span>Deactivate</span>
//               <p>
//                 Are you sure you want to deactivate this Marketplace Admin? This action
//                 cannot be undone.
//               </p>
//               <div className="btn">
//                 <button onClick={() => setDeactivateOverlay(false)}>
//                   Cancel
//                 </button>
//                 <button
//                   onClick={handleDeactivateAccount}
//                   disabled={deactivateMarketplaceManager.isLoading}
//                 >
//                   {deactivateMarketplaceManager.isLoading
//                     ? "Deactivating..."
//                     : "Confirm"}
//                 </button>
//               </div>
//               {deactivateMarketplaceManager.isError && (
//                 <p className="error-message">
//                   Error: {deactivateMarketplaceManager.error.message}
//                 </p>
//               )}
//             </div>
//           </div>
//         </Fragment>
//       )}

//       <div className="super_admin_market-container">
//         <div className="header">
//           <p>Market Place Admin</p>
//           <div className="inner">
//             <span>
//               <SearchIcon />
//               <input
//                 type="search"
//                 placeholder="Search by name"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </span>
//             <button onClick={() => navigate("/Superregisstermarkeplace")}>
//               Register New
//             </button>
//           </div>
//         </div>

//         <div className="market-place-table">
//           {isLoading && <Spinner />}
//           {isError && <p>Error loading market admin</p>}
//           {!isLoading && !isError && (
//             <table>
//               <thead>
//                 <tr>
//                   <th>First Name</th>
//                   <th>Last Name</th>
//                   <th>Address</th>
//                   <th>Email address</th>
//                   <th>Phone Number</th>
//                   <th>Block</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredMarketplaceManagers.map((manager, index) => (
//                   <tr
//                     key={manager._id}
//                     ref={index === filteredMarketplaceManagers.length - 1 ? lastProductElementRef : null}
//                   >
//                     <td>{manager?.first_name}</td>
//                     <td>{manager?.last_name}</td>
//                     <td>{manager?.address || "N/A"}</td>
//                     <td>{manager?.email}</td>
//                     <td>{manager?.phone_number || "N/A"}</td>
//                     <td>{manager?.blocked ? "Yes" : "No"}</td>
//                     <td>
//                       <div className="action">
//                         {manager.blocked ? (
//                           <GoPlusCircle
//                             onClick={() => {
//                               setUnblockOverlay(true);
//                               setSelectedMarketplaceManagerId(manager._id);
//                             }}
//                           />
//                         ) : (
//                           <RemoveIcon
//                             onClick={() => {
//                               setBlockOverlay(true);
//                               setSelectedMarketplaceManagerId(manager._id);
//                             }}
//                           />
//                         )}
//                         <TrashIcon
//                           onClick={() => {
//                             setDeactivateOverlay(true);
//                             setSelectedMarketplaceManagerId(manager._id);
//                           }}
//                         />
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           )}
//         </div>
//         {isFetchingNextPage && <Spinner />}
//       </div>
//     </Fragment>
//   );
// }

// export default Superadminmrarketmanager;

import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import useGetMarketplaceManagers from "../libs/marketplaceManagers";
import { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { GoPlusCircle } from "react-icons/go";
import { CiEdit } from "react-icons/ci";
import useDeactivateAccount from "../libs/deactivateAccount";
import useBlockUserById from "../libs/blockUserById";
import useUnblockUserById from "../libs/unblockUserById";
import useUpdateUserById from "../libs/useUpdateUserById";
import Spinner from "../../utils/spinner";
import { useNavigate } from "react-router-dom";
import "./Superadminm-manager.css";

function Superadminmrarketmanager() {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetMarketplaceManagers();

  const marketplaceManagerArray = data?.pages.flatMap(page => page.marketplaceManagers) || [];
  
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMarketplaceManagerId, setSelectedMarketplaceManagerId] = useState(null);
  const [blockOverlay, setBlockOverlay] = useState(false);
  const [unblockOverlay, setUnblockOverlay] = useState(false);
  const [deactivateOverlay, setDeactivateOverlay] = useState(false);
  const [editOverlay, setEditOverlay] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const blockMarketplaceManager = useBlockUserById();
  const unblockMarketplaceManager = useUnblockUserById();
  const deactivateMarketplaceManager = useDeactivateAccount();

  const filteredMarketplaceManagers = marketplaceManagerArray.filter(
    (manager) =>
      manager.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      manager.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const observerRef = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      }, { threshold: 1.0 });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const handleUnblockUser = async () => {
    try {
      if (!selectedMarketplaceManagerId) {
        console.error("User ID not available.");
        return;
      }

      await unblockMarketplaceManager.mutateAsync({
        userId: selectedMarketplaceManagerId,
      });
      setUnblockOverlay(false);
      setSelectedMarketplaceManagerId(null);
    } catch (error) {
      console.error("Failed to unblock user:", error.message);
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!selectedMarketplaceManagerId) {
        console.error("User ID not available.");
        return;
      }

      await blockMarketplaceManager.mutateAsync({
        userId: selectedMarketplaceManagerId,
        blockData: { startDate, endDate },
      });
      setBlockOverlay(false);
      setSelectedMarketplaceManagerId(null);
    } catch (error) {
      console.error("Failed to block user:", error.message);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      if (!selectedMarketplaceManagerId) {
        console.error("User ID not available.");
        return;
      }

      await deactivateMarketplaceManager.mutateAsync(
        selectedMarketplaceManagerId
      );
      setDeactivateOverlay(false);
      setSelectedMarketplaceManagerId(null);
    } catch (error) {
      console.error("Failed to deactivate account:", error.message);
    }
  };

  const updateUserHook = useUpdateUserById();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    address:'',
    phone_number: '',
    country_code: "+234",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const selectedManager = marketplaceManagerArray?.find(
    (manager) => manager._id === selectedMarketplaceManagerId
  );

  useEffect(() => {
    if (!isLoading && selectedMarketplaceManagerId) {
      const allMarketplaceManagers =
        data?.pages.flatMap((page) => page.marketplaceManagers) || [];

      const selectedManager = allMarketplaceManagers.find(
        (manager) => manager._id === selectedMarketplaceManagerId
      );

      if (selectedManager) {
        setFormData({
          first_name: selectedManager.first_name || "",
          last_name: selectedManager.last_name || "",
          email: selectedManager.email || "",
          address: selectedManager.address || "",
          phone_number: selectedManager.phone_number || "",
          country_code:selectedManager.country_code ||  "",
        });
      } else {
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          address: "",
          phone_number: "",
          country_code: "",
        });
      }
    }
  }, [selectedMarketplaceManagerId, isLoading, data]);

  const handleEditClick = (manager) => {
    if (manager) {
      setSelectedMarketplaceManagerId(manager._id);  
      setFormData({
        first_name: manager.first_name || "",
        last_name: manager.last_name || "",
        email: manager.email || "",
        phone_number: manager.phone_number || "",
        address: manager.address || "", 
        country_code: "",
      });
      setEditOverlay(true);
    }
  };
  

  const handleCancelEdit = () => {
    setSelectedMarketplaceManagerId(null);
    setEditOverlay(false);
  };

  const handleUpdateMarketplaceManager = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      if (selectedMarketplaceManagerId) {
        await updateUserHook.updateUserById(
          selectedMarketplaceManagerId,
          formData
        );
      }
      setEditOverlay(false);
    } catch (error) {
      console.error("Failed to update Marketplace Manager:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Fragment>
       {editOverlay && (
        <div className="edits-over-container">
          <form className="card">
            <label>
              First Name
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Last Name
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Email
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Home address
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Phone Number
              <input
                type="tel"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
              />
            </label> 
            <label>
              Country Code
              <input
                type="tel"
                name="country_code"
                value={formData.country_code}
                onChange={handleInputChange}
              />
            </label>
            <div className="btn">
              <button onClick={handleCancelEdit}>Cancel</button>
              <button onClick={handleUpdateMarketplaceManager}>
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}
      {blockOverlay && (
        <Fragment>
          <div className="block-over-container">
            <form>
              <p>Are you sure you want to suspend this Marketplace Admin?</p>
              <label>
                Start Date
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
              <label> 
                End Date
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </label>
              <div className="btn">
                <button onClick={() => setBlockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleBlockUser}
                  disabled={blockMarketplaceManager.isLoading}
                >
                  {blockMarketplaceManager.isLoading ? "Blocking..." : "Confirm"}
                </button>
              </div>
              {blockMarketplaceManager.isError && (
                <p className="error-message">
                  Error: {blockMarketplaceManager.error.message}
                </p>
              )}
            </form>
          </div>
        </Fragment>
      )}

      {unblockOverlay && (
        <Fragment>
          <div className="unblock-over-container">
            <div className="inner">
              <p>
                Are you sure you want to lift the suspension on this Marketplace Admin?
              </p>
              <div className="btn">
                <button onClick={() => setUnblockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleUnblockUser}
                  disabled={unblockMarketplaceManager.isLoading}
                >
                  {unblockMarketplaceManager.isLoading
                    ? "Unblocking..."
                    : "Confirm"}
                </button>
              </div>
              {unblockMarketplaceManager.isError && (
                <p className="error-message">
                  Error: {unblockMarketplaceManager.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}

      {deactivateOverlay && (
        <Fragment>
          <div className="delete-over-container">
            <div className="delete-card">
              <TrashIcon />
              <span>Deactivate</span>
              <p>
                Are you sure you want to deactivate this Marketplace Admin? This action
                cannot be undone.
              </p>
              <div className="btn">
                <button onClick={() => setDeactivateOverlay(false)}>
                  Cancel
                </button>
                <button
                  onClick={handleDeactivateAccount}
                  disabled={deactivateMarketplaceManager.isLoading}
                >
                  {deactivateMarketplaceManager.isLoading
                    ? "Deactivating..."
                    : "Confirm"}
                </button>
              </div>
              {deactivateMarketplaceManager.isError && (
                <p className="error-message">
                  Error: {deactivateMarketplaceManager.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}

      <div className="super_admin_market-container">
        <div className="header">
          <p>Market Place Admin</p>
          <div className="inner">
            <span>
              <SearchIcon />
              <input
                type="search"
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </span>
            <button onClick={() => navigate("/Superregisstermarkeplace")}>
              Register New
            </button>
          </div>
        </div>

        <div className="market-place-table">
          {isLoading && <Spinner />}
          {isError && <p>Error loading market admin</p>}
          {!isLoading && !isError && (
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Address</th>
                  <th>Email address</th>
                  <th>Phone Number</th>
                  <th>Block</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredMarketplaceManagers.map((manager, index) => (
                  <tr
                    key={manager._id}
                    ref={index === filteredMarketplaceManagers.length - 1 ? lastProductElementRef : null}
                  >
                    <td>{manager?.first_name}</td>
                    <td>{manager?.last_name}</td>
                    <td>{manager?.address || "N/A"}</td>
                    <td>{manager?.email}</td>
                    <td>{manager?.phone_number || "N/A"}</td>
                    <td>{manager?.blocked ? "Yes" : "No"}</td>
                    <td>
                      <div className="action">
                      <CiEdit onClick={() => handleEditClick(manager)} />
                        {manager.blocked ? (
                          <GoPlusCircle
                            onClick={() => {
                              setUnblockOverlay(true);
                              setSelectedMarketplaceManagerId(manager._id);
                            }}
                          />
                        ) : (
                          <RemoveIcon
                            onClick={() => {
                              setBlockOverlay(true);
                              setSelectedMarketplaceManagerId(manager._id);
                            }}
                          />
                        )}
                        <TrashIcon
                          onClick={() => {
                            setDeactivateOverlay(true);
                            setSelectedMarketplaceManagerId(manager._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && <Spinner />}
      </div>
    </Fragment>
  );
}

export default Superadminmrarketmanager;

