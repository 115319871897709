import React, { useState, useEffect, useRef, useCallback , Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as SearchIcon} from "../../asset/iconamoon_search-light.svg";
import {ReactComponent as TrashIcon} from "../../asset/basil_trash-outline.svg";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import { CiEdit } from "react-icons/ci";
import useFetchShippingCost from '../libs/fetchShippingCost';
import useDeleteShipCost from '../libs/useDeleteShipCost';
import useUpdateShippingCostById from '../libs/useUpdateShipCostById';
import Spinner from '../../utils/spinner';
import "./SuperadminshippingCost.css";

const Superadminshippingcost = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCostId, setSelectedCostId] = useState(null);
    const [deleteOverlay, setDeleteOverlay] = useState(false);
    const [editOverlay, setEditOverlay] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const {
        data, 
        isLoading, 
        isError, 
        fetchNextPage, 
        isFetchingNextPage, 
        hasNextPage 
    } = useFetchShippingCost();

    const updateShippingCost = useUpdateShippingCostById();
    const deleteShippingCostMutation = useDeleteShipCost();

    const [costData, setCostData] = useState({
        sender_state: "",
        receiver_state: "",
        base_cost: "",
        additional_cost_per_half_kg: "",
      });


  const handleInputChange = (e) => {
    setCostData({
      ...costData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!isLoading && data && selectedCostId) {
      const allShippingCost = data?.pages?.flatMap((page) => page.shippingCost) || [];
  
      const selectedShippingCost = allShippingCost.find(
        (shippingCost) => shippingCost?._id === selectedCostId
      );
  
      if (selectedShippingCost) {
        console.log('Selected Shipping Cost:', selectedShippingCost);
        setCostData({
          sender_state: selectedShippingCost.sender_state || "",
          receiver_state: selectedShippingCost.receiver_state || "",
          base_cost: selectedShippingCost.base_cost || "",
          additional_cost_per_half_kg: selectedShippingCost.additional_cost_per_half_kg || "",
        });
      }
    }
  }, [selectedCostId, isLoading, data]);
  
  const handleEditClick = (shippingCost) => {
    if (shippingCost) {
      setSelectedCostId(shippingCost._id);
      setCostData({
        sender_state:shippingCost.sender_state || "",
        receiver_state:shippingCost.receiver_state || "",
        base_cost:shippingCost.base_cost || "",
        additional_cost_per_half_kg:shippingCost.additional_cost_per_half_kg || "",
      });
      setEditOverlay(true);
    }
  };

  const handleCancelEdit = () => {
    setSelectedCostId(null);
    setEditOverlay(false);
  };

  const handleUpdateShippingCost = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      if (selectedCostId) {
        await updateShippingCost.updateShippingCostById(selectedCostId, costData);
      }
      setEditOverlay(false);
    } catch (error) {
      console.error("Failed to update shipping:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };
  
  const handleDeleteShippingCost = async () => {
    try {
      setLoading(true)
      if (!selectedCostId) {
        console.error("Cost ID not available.");
        return;
      }
      await deleteShippingCostMutation.deleteShipCost(selectedCostId);
      setDeleteOverlay(false);
      setSelectedCostId(null);
      setLoading(false)
    } catch (error) {
      console.error("Failed to delete shipping cost:", error.message);
    }
  };
  

    const observerRef = useRef();
    const lastShippingCostRef = useCallback(
        (node) => {
            if (isFetchingNextPage) return;
            if (observerRef.current) observerRef.current.disconnect();

            observerRef.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    fetchNextPage();
                }
            });

            if (node) observerRef.current.observe(node);
        },
        [isFetchingNextPage, fetchNextPage, hasNextPage]
    );

    const shippingCostArray = data?.pages?.flatMap(page => page.shippingCosts) || [];


    const filteredShippingCost = shippingCostArray.filter(
        shippingCost =>
            shippingCost.sender_state.toLowerCase().includes(searchTerm.toLowerCase()) ||
            shippingCost.receiver_state.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Fragment>
             {editOverlay && (
        <div className="edits-over-container">
          <form className="card">
            <label>
             Sender State
              <input
                type="text"
                name="sender_state"
                value={costData.sender_state}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Receiver State
              <input
                type="text"
                name="receiver_state"
                value={costData.receiver_state}
                onChange={handleInputChange}
              />
            </label>
            <label>
             Base Cost
              <input
                type="tel"
                name="base_cost"
                value={costData.base_cost}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Additional Cost Per Half kg
              <input
                type="tel"
                name="additional_cost_per_half_kg"
                value={costData.additional_cost_per_half_kg}
                onChange={handleInputChange}
              />
            </label>
        
            <div className="btn">
              <button onClick={handleCancelEdit}>Cancel</button>
              <button onClick={handleUpdateShippingCost}>
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}
       {deleteOverlay && (
        <div className="delete-over-container">
          <div className="delete-card">
            <CautionIcon />
            <span>Delete</span>
            <p>
              Are you sure you want to delete this shipping cost? This action cannot
              be undone?
            </p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>Cancel</button>
              <button onClick={handleDeleteShippingCost}>
                {loading
                  ? "Deleting..."
                  : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
        <div className='all-shipping-cost'>
            <div className="header">
                <p>Shipping Cost</p>
                <div className="in">
                    <span>
                        <SearchIcon />
                        <input 
                            type="search" 
                            placeholder='search cost by state' 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} 
                            />
                    </span>
                    <div className="btn">
                        <button onClick={() => navigate('/superadmingetquote')}>Get Quote</button>
                        <button onClick={() => navigate('/superadmincreateshipcost')}>Create Cost</button>
                    </div>
                </div>
            </div>

            <div className="shipping-cost-table">
            {isLoading ?(
              <Spinner />
            ) : isError ? (
              <p style={{fontFamily:'Montserrat'}}>No shipping cost found.</p>
            ) :(
                <table>
                    <thead>
                        <tr>
                            <th>Sender State</th>
                            <th>Receiver State</th>
                            <th>Base cost</th>
                            <th>Additional Cost</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredShippingCost.map((shippingCost, index) => (
                            <tr key={shippingCost._id} ref={index === filteredShippingCost.length - 1 ? lastShippingCostRef : null}>
                                <td>{shippingCost.sender_state}</td>
                                <td>{shippingCost.receiver_state}</td>
                                <td>{shippingCost.base_cost}</td>
                                <td>{shippingCost.additional_cost_per_half_kg}</td>
                                <td>
                                    <div className="action">
                                        <CiEdit onClick={() => handleEditClick(shippingCost)} />
                                        <TrashIcon
                              onClick={() => {
                                setDeleteOverlay(true);
                                setSelectedCostId(shippingCost._id);
                              }}
                            />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            </div>
            {isFetchingNextPage && <Spinner />}
        </div>
     </Fragment>
    );
}

export default Superadminshippingcost;
