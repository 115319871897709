import useAdminMessages from "../libs/fetchLogisticBroadMsg";
import useDeleteBroadMsg from "../libs/LogisticDeleteBroadMsg";
import Spinner from "../../Spinner/Spinner";
import { GlobalContext } from "../../GlobalContext/GlobalContext";
import React, { useContext, useRef, useCallback } from "react";
import { ReactComponent as CautionIcon } from "../../Assets/caution-svgrepo-com 1.svg";
import "./LogisticBroadcastMsg.css";

const LogisticBroadcastMsg = () => {
  const { 
    data, 
    isLoading, 
    isError, 
    isFetchingNextPage, 
    fetchNextPage, 
    hasNextPage, 
  } = useAdminMessages();

  const { 
    deleteBroadcastMessage, 
    setDeleteBroadcastMessage, 
    messageId, 
    setMessageId 
  } = useContext(GlobalContext);

  const { isDeleting, handleDeleteMessage } = useDeleteBroadMsg();

  const observerRef = useRef();

  const adminMessages = data ? data.pages.flatMap(page => page.messages) : [];

  const lastMessageElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 1.0 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <>
      <div className="logistic-broadcast-msg">
        <div className="header">
          <p>Broadcast Message</p>
        </div>
        {isLoading && <Spinner />}
        {isError && <p>Error fetching admin messages</p>}
        {adminMessages.length > 0 && (
          <div className="admin-chat-card">
            {deleteBroadcastMessage && (
              <div className="delete-broadcast-message">
                <div className="wrapper">
                  <CautionIcon />
                  <h3>Delete</h3>
                  <p>Are you sure you want to delete the selected message?</p>
                  <span>
                    <button
                      onClick={() => {
                        setDeleteBroadcastMessage(false);
                        setMessageId(null);
                      }}
                    >
                      No
                    </button>
                    <button
                      onClick={async () => {
                        try {
                          await handleDeleteMessage(messageId);
                          setDeleteBroadcastMessage(false);
                          setMessageId(null);
                        } catch (error) {
                          console.error("Error deleting message:", error);
                        }
                      }}
                      disabled={isDeleting}
                    >
                      {isDeleting ? "Deleting..." : "Yes"}
                    </button>
                  </span>
                </div>
              </div>
            )}
            <ul className="admin-chat-info">
              {adminMessages.map((message, index) => {
                const isLastMessage = adminMessages.length === index + 1;
                return (
                  <li 
                    key={message.id} 
                    ref={isLastMessage ? lastMessageElementRef : null}
                  >
                    <span>
                      <p>
                        Sender: {message?.sender.first_name}{" "}
                        {message?.sender.last_name}
                      </p>
                      <p>{new Date(message?.timestamp).toLocaleString()}</p>
                    </span>
                    <p>{message?.content}</p>
                    <div className="button-card">
                      <button
                        onClick={() => {
                          setDeleteBroadcastMessage(true);
                          setMessageId(message.id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {isFetchingNextPage && (
          <div className="loading-more">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default LogisticBroadcastMsg;

