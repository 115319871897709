import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import MarketNavbar from '../../MarketNav/MarketNavbar';
import CartItemPayment from './CartItemPayment';
import useFormData from '../../../../Context/UseFormData';
import "./CartPayment.css";

const ShippingDetail = () => {
    const [formData, handleChange] = useFormData("shippingDetailFormData");
    const [isOpenPayment, setIsOpenPayment] = useState(false);

    const handleOpenPayment = () => {
        setIsOpenPayment(true);
    };

    const handleClosePayment = () => {
        setIsOpenPayment(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("formData:", formData);
    };

    return (
        <Fragment>
            <MarketNavbar />
            <div className="shipping-detail-wrapper">
                <div className='shipping-details-icon'>
                    <Link to='/CartListView'>Shopping Bag</Link>/
                    <h3>Shipping Details</h3>/
                    <p>Payment</p>
                </div>
                <span>
                    <h3>Shipping Details</h3>
                </span>
                <div className="shipping-span">
                    <div className="shipping-details-form">
                        <form onSubmit={handleSubmit}>
                            <div className="wrapper">
                                <div className="input-market-combination">
                                    <span>
                                        <label>Full Name</label>
                                        <input type="text" name="full_name" value={formData.full_name || ''} onChange={handleChange} required />
                                    </span>
                                    <span>
                                        <label>Email Address</label>
                                        <input type="email" name="email" value={formData.email || ''} onChange={handleChange} required />
                                    </span>
                                </div>

                                <div className="input-market-combination">
                                    <span>
                                        <label>Phone Number</label>
                                        <input type="tel" name="phone_number" value={formData.phone_number || ''} onChange={handleChange} required />
                                    </span>
                                    <span>
                                        <label>Phone Num (optional)</label>
                                        <input type="tel" name="second_phone" value={formData.second_phone || ''} onChange={handleChange} />
                                    </span>
                                </div>

                                <div className="input-market-combination">
                                    <span>
                                        <label>City</label>
                                        <input type="text" name="city" value={formData.city || ''} onChange={handleChange} required />
                                    </span>

                                    <span>
                                        <label>State</label>
                                        <select name="state" value={formData.state || 'select State'} onChange={handleChange} required>
                                            <option value="select State">select State</option>
                                            <option value="Lagos">Lagos</option>
                                            <option value="Oyo">Oyo</option>
                                            <option value="Ogun">Ogun</option>
                                            <option value="Osun">Osun</option>
                                            <option value="Ondo">Ondo</option>
                                            <option value="Ekiti">Ekiti</option>
                                            <option value="Kwara">Kwara</option>
                                        </select>
                                    </span>
                                </div>
                                <span>
                                    <label>Address</label>
                                    <input type="text" name="address" value={formData.address || ''} onChange={handleChange} required />
                                </span>
                            </div>
                            <div className="payment-together">
                                <CartItemPayment />
                                <button onClick={handleOpenPayment}> Proceed to Payment </button>
                                <div className="payment-container">
                                    {isOpenPayment && (
                                        <div className="payment-button-2">
                                            <button onClick={handleClosePayment}>Close</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ShippingDetail;
