import { useState, useRef, useCallback } from "react";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import useGetToken from "../libs/useGetAdmin";
import "./superadmintoken.css";
import Spinner from "../../utils/spinner";

const SuperadminToken = () => {
  const { 
    data: users, 
    isLoading, 
    isError, 
    fetchNextPage, 
    hasNextPage, 
    isFetchingNextPage 
  } = useGetToken();
  
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const allUsers = users?.pages.flatMap(page => page.users) || [];

  const filteredUsers = allUsers.filter(user =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.phone_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
    if (!dateString) return "NA";
    const date = new Date(dateString);
    return date.toLocaleString(); 
  };

  const observerRef = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      }, { threshold: 1.0 });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className="superadmin-token-container">
      <div className="header">
        <p>Token</p>
        <span>
          <SearchIcon />
          <input
            type="search"
            placeholder="Search by email or phone or name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </span>
      </div>

      <div className="user-token-container">
        {isLoading && <Spinner />}
        {isError && <p style={{fontFamily:'Montserrat'}}>No users</p>}
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>OTP</th>
              <th>OTP EXP</th>
              <th>Act Code</th>
              <th>Reset code</th>
              <th>Reset Expired</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers && filteredUsers.map((user, index) => {
              const isLastUser = index === filteredUsers.length - 1;
              return (
                <tr
                  key={user._id}
                  ref={isLastUser ? lastProductElementRef : null} 
                >
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.country_code} {user.phone_number}
                  </td>
                  <td>{user.oneTimeVerificationCode || "NA"}</td>
                  <td>{formatDate(user.oneTimeVerificationCodeExpiration)}</td>
                  <td>{user.activationCode || "NA"}</td>
                  <td>{user.resetCode || "NA"}</td>
                  <td>{formatDate(user.resetCodeExpiration)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isFetchingNextPage && <Spinner />}
    </div>
  );
};

export default SuperadminToken;
