import React, { Fragment, useState, useRef, useCallback } from "react";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import usePackagesByCreator from "../libs/usePackagesByCreator";
import useRidersByBranch from "../libs/useRidersByBranch";
import Spinner from "../../utils/spinner";
import { ReactComponent as EllipIcon } from "../../asset/Ellipse 3685.svg";
import { ReactComponent as BlockedIcon } from "../../asset/Blocked 18.svg";
import useAssignPackagesToRider from "../libs/useAssignPackagesToRider";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 2.svg";
import "./Logissticstrips.css";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function Logisticstrips() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchRiderQuery, setSearchRiderQuery] = useState("");
  const [addedPackages, setAddedPackages] = useState([]);
  const [addedRiders, setAddedRiders] = useState([]);
  const [duplicateError, setDuplicateError] = useState(false);
  const [assignedOverlay, setAssignedOverlay] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [assignError, setAssignError] = useState(null);
 
  const {
    data,
    isLoading: isPackageBranchLoading,
    isError: isPackageBranchError,
    fetchNextPage: fetchNextPackageBranchPage,
    hasNextPage: hasNextPackageBranchPage,
    isFetchingNextPage: isFetchingNextPackageBranchPage,
  } = usePackagesByCreator();

  const packageBranchObserverRef = useRef();
  const lastPackageBanchElementRef = useCallback(
    (node) => {
      if (isFetchingNextPackageBranchPage) return;

      if (packageBranchObserverRef.current)
        packageBranchObserverRef.current.disconnect();

      packageBranchObserverRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPackageBranchPage) {
            fetchNextPackageBranchPage();
          }
        },
        { threshold: 0.5 }
      );

      if (node) packageBranchObserverRef.current.observe(node);
    },
    [
      isFetchingNextPackageBranchPage,
      fetchNextPackageBranchPage,
      hasNextPackageBranchPage,
    ]
  );

  const {
    data: ridersData,
    isLoading: riderLoading,
    isError: isRiderError,
    fetchNextPage: fetchNextRiderPage,
    hasNextPage: hasNextRiderPage,
    isFetchingNextPage: isFetchingNextRiderPage,
  } = useRidersByBranch();

  const riderObserverRef = useRef();
  const lastDriverElementRef = useCallback(
    (node) => {
      if (isFetchingNextRiderPage) return;

      if (riderObserverRef.current) riderObserverRef.current.disconnect();

      riderObserverRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextRiderPage) {
            fetchNextRiderPage();
          }
        },
        { threshold: 0.5 }
      );

      if (node) riderObserverRef.current.observe(node);
    },
    [isFetchingNextRiderPage, fetchNextRiderPage, hasNextRiderPage]
  );


  const assignPackagesToRiderrMutation = useAssignPackagesToRider();

 
  const handleCreateTrip = async () => {
    setAssignLoading(true);

    const requestData = {
      packageIds: addedPackages.map((pkg) => pkg._id),
      userId: addedRiders[0]._id,
    };

    try {
      await assignPackagesToRiderrMutation.mutateAsync(requestData);

      setAssignedOverlay(false);
      setAddedPackages([]);
    } catch (error) {
      setAssignError("Error assigning packages to driver");
    } finally {
      setAssignLoading(false);
    }
  };

  const isRiderAlreadyAdded = (rider) => addedRiders.some((addedRider) => addedRider._id === rider._id);

  const handleAddRider = (rider) => {
    if (!isRiderAlreadyAdded(rider)) {
      setAddedRiders((prevAddedRiders) => [...prevAddedRiders, rider]);
      setDuplicateError(false);
    } else {
      setDuplicateError(true);
    }
  };

  const handleRemoveRider = (rider) => {
    setAddedRiders((prevAddedRiders) =>
      prevAddedRiders.filter((addedRider) => addedRider._id !== rider._id)
    );
    setDuplicateError(false);
  };

  const packages = data?.pages.flatMap((page) => page.packages) || [];
  const filteredPackages = packages
    .filter(
      (pkg) => !pkg?.status.some((status) => status?.status === "Delivered")
    )
    .filter((pkg) =>
      pkg?.receiver?.state.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const riders = ridersData?.pages.flatMap((page) => page.riders) || [];
  const filteredRiders = riders.filter((rider) => {
    const fullName = `${rider.first_name} ${rider.last_name}`.toLowerCase();
    return fullName.includes(searchRiderQuery.toLowerCase());
  });

  const formatRiderID = (id) => id.slice(0, 8).toUpperCase();


  const isPackageAlreadyAdded = (pkg) => addedPackages.some((addedPkg) => addedPkg._id === pkg._id);

  const handleAddPackage = (pkg) => {
    if (!isPackageAlreadyAdded(pkg)) {
      setAddedPackages((prevAddedPackages) => [...prevAddedPackages, pkg]);
      setDuplicateError(false);
    } else {
      setDuplicateError(true);
    }
  };

  const handleRemovePackage = (pkg) => {
    setAddedPackages((prevAddedPackages) =>
      prevAddedPackages.filter((addedPkg) => addedPkg._id !== pkg._id)
    );
    setDuplicateError(false);
  };

  return (
    <Fragment>
      {assignedOverlay && (
        <div className="assigned-over">
          <div className="card">
            <CautionIcon />
            <h3>Create Trip</h3>
            <p>Are you sure you want to create a new Trip?</p>
            <div className="btn">
              <button onClick={() => setAssignedOverlay(false)}>No</button>
              <button onClick={handleCreateTrip} disabled={assignLoading}>
                {assignLoading ? <Spinner /> : "Create"}
              </button>
            </div>
            {assignError && <p>{assignError}</p>}
          </div>
        </div>
      )}

      <div className="logistictrip-container">
        <div className="header">
          <p>Local Trips</p>
          <button
            onClick={() => setAssignedOverlay(true)}
            disabled={addedPackages?.length === 0 || addedRiders?.length === 0}
          >
            Create Trip
          </button>
        </div>

        <div className="trip-dispatch">
          <div className="dispatches">
            <span>
              <SearchIcon />
              <input
                type="search"
                placeholder="Search by State"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </span>

            <div className="card">
              <p>Create Trips</p>
              {isPackageBranchLoading && <Spinner />}
              {isPackageBranchError && <p>{isPackageBranchError}</p>}
              {filteredPackages.length > 0
                ? filteredPackages.map((pkg, index) => (
                    <div
                      key={pkg._id}
                      ref={
                        index === filteredPackages.length - 1
                          ? lastPackageBanchElementRef
                          : null
                      }
                      className="package-item"
                    >
                      <div className="items">
                        <h3>{pkg.tracking_number}</h3>
                        <p>
                          {pkg.receiver.address_1}, {pkg.receiver.state}
                        </p>
                        <p>{pkg.name}</p>
                        <p>{formatDate(pkg.estimated_delivery_date)}</p>
                      </div>
                      <button onClick={() => handleAddPackage(pkg)}>Add</button>
                    </div>
                  ))
                : !isPackageBranchLoading && <p>No packages found.</p>}
            </div>
            {isFetchingNextPackageBranchPage && (
              <div className="loading-more">
                <Spinner />
              </div>
            )}
          </div>

          <div className="added-package">
            {addedPackages.map((addedPkg, index) => (
              <div className="card" key={index}>
                <div className="in">
                  <p>{addedPkg.tracking_number}</p>
                  <p>{addedPkg.receiver.state}</p>
                  <p>{addedPkg.name}</p>
                  <p>{formatDate(addedPkg.estimated_delivery_date)}</p>
                </div>
                <button onClick={() => handleRemovePackage(addedPkg)}>
                  Remove
                </button>
              </div>
            ))}
            {duplicateError && (
              <div className="error-message">
                <p>Package with the same ID is already added.</p>
              </div>
            )}
          </div>

          <div className="rider-con">
            <span>
              <SearchIcon />
              <input
                type="text"
                placeholder="Search by Name"
                value={searchRiderQuery}
                onChange={(e) => setSearchRiderQuery(e.target.value)}
              />
            </span>

            {riderLoading ? (
              <Spinner />
            ) : isRiderError ? (
              <p>Error fetching riders</p>
            ) : (
              <div className="rider-by-branch">
                {filteredRiders.length > 0 ? (
                  filteredRiders.map((rider, index) => (
                    <div
                      key={rider._id}
                      ref={
                        index === filteredRiders.length - 1
                          ? lastDriverElementRef
                          : null
                      }
                      className="card"
                    >
                      <div className="inner">
                        <h3>{`${rider?.first_name} ${rider?.last_name}`}</h3>
                        <span>ID : {formatRiderID(rider?._id)}</span>
                        <p>Rider</p>
                        <p>
                          {rider?.blocked ? <BlockedIcon /> : <EllipIcon />}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          isRiderAlreadyAdded(rider)
                            ? handleRemoveRider(rider)
                            : handleAddRider(rider)
                        }
                      >
                        {isRiderAlreadyAdded(rider) ? "Undo" : "Add"}
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No riders found.</p>
                )}
              </div>
            )}
          </div>
        </div>
          {isFetchingNextRiderPage && (
            <div className="loading-more">
              <Spinner />
            </div>
          )}
      </div>
    </Fragment>
  );
}

export default Logisticstrips;
