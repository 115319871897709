import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import useFetchLogisticsOfficers from "../libs/useFetchLogisticsOfficers";
import useUpdateUserById from "../libs/useUpdateUserById";
import { GoPlusCircle } from "react-icons/go";
import useDeactivateAccount from "../libs/deactivateAccount";
import useBlockUserById from "../libs/blockUserById";
import useUnblockUserById from "../libs/unblockUserById";
import { useNavigate } from "react-router-dom";
import { Fragment, useState, useEffect, useRef, useCallback } from "react";
import Spinner from "../../utils/spinner";
import { CiEdit } from "react-icons/ci";
import "./superadminlogistic.css";

function Superadminlogistic() {
  const {
    data: logisticsOfficersData,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useFetchLogisticsOfficers();

  const updateUserHook = useUpdateUserById();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    address:'',
    branchFullAddress: '',
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [blockOverlay, setBlockOverlay] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [unblockOverlay, setUnblockedOverlay] = useState(false);
  const [deleteOverlay, setDeleteOerlay] = useState(false);
  const [editOverlay, setEditOverlay] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const blockUser = useBlockUserById();
  const unblockUser = useUnblockUserById();
  const deactivateAccountMutation = useDeactivateAccount();

  const officersArray = logisticsOfficersData?.pages.flatMap(page => page.logisticsManagers) || [];

  const filteredOfficers = officersArray.filter(
    (officer) =>
      officer.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      officer.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const selectedOfficer = officersArray.find(
    (officer) => officer._id === selectedUserId
  );

  const handleEditClick = () => {
    setFormData({
      firstName: selectedOfficer?.first_name || '',
      lastName: selectedOfficer?.last_name || '',
      email: selectedOfficer?.email || '',
      address: selectedOfficer?.address || '',
      branchFullAddress: selectedOfficer?.branch_full_address || '',
    });
    setEditOverlay(true);
  };

  useEffect(() => {
    if (selectedUserId !== null) {
      setFormData({
        firstName: selectedOfficer?.first_name || '',
        lastName: selectedOfficer?.last_name || '',
        email: selectedOfficer?.email || '',
        address: selectedOfficer?.address || '',
        branchFullAddress: selectedOfficer?.branch_full_address || '',
      });
    } else {
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        branchFullAddress: '',
        address: '',
      });
    }
  }, [selectedUserId, selectedOfficer]);

  const handleCancelEdit = () => {
    setSelectedUserId(null);
    setEditOverlay(false);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      if (!selectedUserId) {
        return;
      }

      await updateUserHook.updateUserById(selectedUserId, {
        userId: selectedUserId,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        address: formData.address,
        branch_full_address: formData.branchFullAddress,
      });

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        branchFullAddress: '',
        address: ''
      });
      setEditOverlay(false);
    } catch (error) {
      console.error("Failed to update user:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!selectedUserId) {
        console.error("User ID not available.");
        return;
      }

      await blockUser.mutateAsync({
        userId: selectedUserId,
        blockData: { startDate, endDate },
      });
      setBlockOverlay(false);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to block user:", error.message);
    }
  };

  const handleUnblockedUser = async () => {
    try {
      if (!selectedUserId) {
        console.error("User ID not available.");
        return;
      }

      await unblockUser.mutateAsync({ userId: selectedUserId });
      setUnblockedOverlay(false);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to unblock user:", error.message);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      if (!selectedUserId) {
        console.error("User ID not available.");
        return;
      }

      await deactivateAccountMutation.mutateAsync(selectedUserId);
      setDeleteOerlay(false);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to deactivate account:", error.message);
    }
  };

  const observerRef = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      }, { threshold: 1.0 });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <Fragment>
       {
        editOverlay && (
          <div className="edits-over-container">
            <form className="card">
              <label>
                First Name
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Last Name
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Email
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Home address
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Branch Full Address
                <input
                  type="text"
                  name="branchFullAddress"
                  value={formData.branchFullAddress}
                  onChange={handleInputChange}
                />
              </label>
              <div className="btn">
                <button onClick={handleCancelEdit}>Cancel</button>
                <button onClick={handleUpdateUser}>
                  {isUpdating ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        )
      }
      {deleteOverlay && (
        <div className="delete-over-container">
          <div className="delete-card">
            <CautionIcon />
            <span>Delete</span>
            <p>
              Are you sure you want to delete this account? This action cannot
              be undone?
            </p>
            <div className="btn">
              <button onClick={() => setDeleteOerlay(false)}>Cancel</button>
              <button onClick={handleDeactivateAccount}>
                {deactivateAccountMutation.isLoading
                  ? "Deactivating..."
                  : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
      {unblockOverlay && (
        <div className="unblock-over-container">
          <div className="inner">
            <p>Are you sure you want to lift the suspension on this user?</p>
            <div className="btn">
              <button onClick={() => setUnblockedOverlay(false)}>Cancle</button>
              <button
                type="button"
                disabled={unblockUser.isLoading}
                onClick={handleUnblockedUser}
              >
                {" "}
                {unblockUser.isLoading ? "Unblocking..." : "Confirm"}
              </button>
            </div>
            {unblockUser.isError && (
              <p className="error-message">
                Error: {unblockUser.error.message}
              </p>
            )}
          </div>
        </div>
      )}
      {blockOverlay && (
        <div className="block-over-container">
          <form>
            <p>Are you sure you want to suspend this officer?</p>
            <label>
              Start Date
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </label>
            <label>
              End Date
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </label>
            <div className="btn">
              <button onClick={() => setBlockOverlay(false)}>Cancel</button>
              <button
                type="button"
                onClick={handleBlockUser}
                disabled={blockUser.isLoading}
              >
                {blockUser.isLoading ? "Blocking..." : "Confirm"}
              </button>
            </div>
            {blockUser.isError && (
              <p className="error-message">Error: {blockUser.error.message}</p>
            )}
          </form>
        </div>
      )}
      
      <div className="super_admin_logistic_container">
        <div className="header">
          <p>Logistics Officer</p>
          <div className="in">
            <span>
              <SearchIcon />
              <input
                type="search"
                placeholder="Search Logistics Officer"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </span>
            <button onClick={() => navigate("/Superadminresgisterlogistics")}>
              Register New
            </button>
          </div>
        </div>
        <div className="logistic-table">
          {isLoading && <Spinner />}
          {isError && <p>Error loading data</p>}
          {!isLoading && !isError && (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First</th>
                  <th>Last</th>
                  <th>Email</th>
                  <th>Branch</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Blocked</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredOfficers.map((officer, index) => (
                  <tr key={officer._id} ref={index === officersArray.length - 1 ? lastProductElementRef : null}>
                    <td>{officer?._id.toUpperCase().slice(0, 8)}</td>
                    <td>{officer?.first_name}</td>
                    <td>{officer?.last_name}</td>
                    <td>{officer?.email}</td>
                    <td>{officer?.branch_full_address}</td>
                    <td>{officer?.phone_number}</td>
                    <td>{officer?.address}</td>
                    <td>{officer?.blocked ? "Yes" : "No"}</td>
                    <td>
                      <div className="action">
                      <CiEdit onClick={() => {
                          setSelectedUserId(officer._id);
                          handleEditClick(); 
                        }} />
                        {officer?.blocked ? (
                          <GoPlusCircle
                            onClick={() => {
                              setUnblockedOverlay(true);
                              setSelectedUserId(officer._id);
                            }}
                          />
                        ) : (
                          <RemoveIcon
                            onClick={() => {
                              setBlockOverlay(true);
                              setSelectedUserId(officer._id);
                            }}
                          />
                        )}
                        <TrashIcon
                          onClick={() => {
                            setDeleteOerlay(true);
                            setSelectedUserId(officer._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
          {isFetchingNextPage && <Spinner />}
      </div>
    </Fragment>
  );
}

export default Superadminlogistic;

